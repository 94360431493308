import * as React from "react"
import support from "../images/support.png";

const Subscribe = () => (  
    <div> 
        
        <div className="min-w-screen flex items-center justify-center">
            <div className="w-full bg-blue-100 px-5 py-16 md:py-16 text-gray-800 font-light">
                <div className="w-full max-w-6xl mx-auto pb-5">
                    <div className="-mx-3 md:flex items-center">
                        <div className="px-3 md:w-2/3 mb-10 md:mb-0">
                            <p className="section-title">24x7 Online Expert Support <span role="img" aria-label="emoji">🙌 </span></p>
                            <p>We are a nimble team of designers and developers always ready for your support. No need of any Customer Care people, because everyone is responsible for customer support here.</p>
                            
                        </div>
                        <div className="px-3 md:w-1/3">
                            <img 
                                className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" 
                                src={support}
                                alt="Sheephostingbay"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="min-w-screen flex items-center justify-center my-24">
            <div class="flex flex-col max-w-4xl md:h-56 bg-white rounded-lg shadow-lg overflow-hidden md:flex-row my-10">
                <div class="md:flex items-center justify-center md:w-1/2 bg-gradient-to-r from-indigo-600 to-pink-500">
                    <div class="py-6 px-8 md:py-0">
                        <h2 class="text-white text-2xl font-bold md:text-white">Join our newsletter <span role="img" aria-label="emoji">✨ </span></h2>
                        <p class="mt-2 text-white md:text-white">Subscribe to our newsletter so we can spam you with offers and discounts.</p>
                    </div>
                </div>
                <div class="flex items-center justify-center pb-6 md:py-0 md:w-1/2 md:border-b-8 border-indigo-500">
                    <form action="https://getform.io/f/63735827-c937-4891-9e82-061ce27deb57" method="POST">
                        <div class="flex flex-col overflow-hidden sm:flex-row">
                            <input class="py-3 px-4 bg-indigo-100 text-white border-indigo-300 border-2 outline-none placeholder-indigo-500 focus:bg-indigo-100" type="email" name="email" placeholder="Enter your email" />
                            <button type="submit" class="py-3 px-4 bg-indigo-500 text-white font-semibold hover:bg-indigo-600">Subscribe</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
)


export default Subscribe
