import * as React from "react"
import { Link } from "gatsby"

import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import Logo from "../images/shb.png"

const NavBar = () => (
<div>
    <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                    <Link to="/">
                        <span className="sr-only">Workflow</span>
                        <img className="h-8 sm:w-40 sm:h-auto sm:pt-5" src={Logo} alt="Sheephostingbay"/>
                    </Link>
                </div>
            </div>
            <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                <Menu>
                    <Link to="/domains/" className="link-menu"><button>Domains</button></Link>
                </Menu>
                <Menu>
                    <Link to="/hosting/" className="link-menu"><button>Hosting</button></Link>
                </Menu>
                <Menu as="div" className="relative inline-block text-left">
                <div className="link-menu">
                    <Menu.Button className="inline-flex justify-center w-full ">
                        PRO Hosting &nbsp;
                            <span className="pt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </span>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                    <Menu.Item>
                        {({ active }) => (
                        <Link to="/vps/" className="link-menu">
                            <button
                                className={`${
                                active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                                VPS
                            </button>   
                        </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <Link to="/dedicated-server/" className="link-menu">
                            <button
                                className={`${
                                active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                                Dedicated Servers
                            </button>
                        </Link>
                        )}
                    </Menu.Item>
                    </div>
                </Menu.Items>
                </Transition>
                </Menu>
                <Menu>
                    <Link to="/support/" className="link-menu"><button>Support</button></Link>
                </Menu>
                <Menu>
                    <a href="https://us.cloudlogin.co/login/" rel="noreferrer" target="_blank" className="font-medium text-indigo-600 hover:text-indigo-500">
                        
                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" className="float-left h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                            </svg>
                            <span>Login</span>
                        </button>
                        
                    </a>
                </Menu>
            </div>
        </nav>
    </div>
    {/* mobile version */}
    <div className="absolute top-0 right-0 p-2 transition transform origin-top-right md:hidden">
        <div className="">
            <div className="px-5 pt-4 items-right">
                {/* <div>
                    <img className="h-8 w-auto" src={Logo} alt="Sheephostingbay"/>
                </div>*/}
                <div className="-mr-2">
                <Menu as="div" >
                    <div className="link-menu">
                        <Menu.Button className="inline-flex justify-center w-full ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                
                                {({ active }) => (
                                    <Link to="/domains/">
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm link-menu`}
                                        >
                                            Domains
                                        </button>
                                    </Link>
                                )}
                                
                            </Menu.Item>
                            <Menu.Item>
                                
                                {({ active }) => (
                                    <Link to="/hosting/">
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm link-menu`}
                                        >
                                            Hosting
                                        </button>
                                    </Link>
                                )}
                                
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                >
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div className="link-menu">
                                            <Menu.Button className="inline-flex justify-center">
                                                PRO Hosting &nbsp;
                                                <span className="pt-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </span>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="px-1 py-1 ">
                                            <Menu.Item>
                                                {({ active }) => (
                                                <button
                                                    className={`${
                                                    active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                >
                                                    VPS
                                                </button>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                <button
                                                    className={`${
                                                    active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                >
                                                    Dedicated Servers
                                                </button>
                                                )}
                                            </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                        </Transition>
                                        </Menu>
                                </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                
                                {({ active }) => (
                                    <Link to="/support/">
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm link-menu`}
                                        >
                                            Support
                                        </button>
                                    </Link>
                                )}
                                
                            </Menu.Item>
                            <Menu.Item>
                                
                                {({ active }) => (
                                    <a href="https://us.cloudlogin.co/login/" rel="noreferrer" target="_blank" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    <button
                                        className={`${
                                        active ? 'bg-violet-500 text-indigo-900' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm link-menu`}
                                    >
                                        Login
                                    </button>
                                    </a>
                                )}
                               
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                    </Transition>
                </Menu>
                
                </div>
            </div>
                     
        </div>
    </div>
</div>
)


export default NavBar
