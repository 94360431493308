import * as React from "react"
import Paypal from '../images/payp.png'
import Visa from '../images/visa.png'
import Mastercard from '../images/mastercard.png'
import Diners from '../images/diners.png'
import JCB from '../images/jcb.png'

const Footer = () => (   
    <footer className="bg-indigo-900 pt-10">
        <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-left">
            
            <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
                
                <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                    About Us
                </div>

                
                <a href="/about-us" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    About Us
                </a>
                <a href="/contact-us" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Contact Us
                </a>
                <a href="/why-us" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Why Us
                </a>
                <a href="/website-migration" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Website Migration
                </a>
                <a href="/hosting-platform" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Hosting Platform
                </a>
                <a href="/warranties" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Service Guarantee
                </a>
                <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Legal
                </a>
                <a href="/pdf-catalogs" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    PDF Catalogs
                </a>
            </div>

            
            <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
                
                <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                    Control Panel
                </div>

                
                <a href="/web-control-panel" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Web Hosting Control Panel
                </a>
                <a href="/cpanel-comparison" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Hepsia vs Cpanel
                </a>
                <a href="/website-accelerator-tools" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Web Accelerators
                </a>
                <a href="/free-themes" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Free Themes
                </a>
                <a href="/domain-names-manager" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Domain Names Manager
                </a>
                <a href="/file-manager" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    File Manager
                </a>
                <a href="/mail-accounts-manager" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Hepsia Email Manager
                </a>
                <a href="/web-stats-manager" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Stats Manager
                </a>
                <a href="/databases-manager" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Databases Manager
                </a>
                <a href="/complimentary-web-site-building-tool" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Free Site Builder
                </a>
                <a href="/online-site-installer" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Website Installer
                </a>
                <a href="/1-click-framework-installer" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    1 Click Framework Installer
                </a>
                <a href="/web-applications" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Marketing Tools
                </a>
                <a href="/advanced-tools" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Advance Tools
                </a>
            </div>

            
            <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
                
                <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                    Support
                </div>

                
                <a href="/support" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Support
                </a>
                <a href="/website-hosting-client-support-service" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Website Hosting Support
                </a>
                <a href="/vps-client-support-service" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    VPS Support
                </a>
                <a href="/semi-dedicated-hosting-client-support-service" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Semi-Dedicated Hosting Support
                </a>
                <a href="/dedicated-hosting-client-support-service" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Dedicated Hosting Support
                </a>
                <a href="/administration-services" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    Dedicated Administration Services
                </a>
                <a href="/vps-packages" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                    VPS Administration Services
                </a>
            </div>

            
            <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
                
                <div className="text-xs uppercase text-gray-400 font-medium mb-6">
                    Contact Us
                </div>

                
                <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                Our ID: 290832
                </a>
                <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                US: +1-855-211-0932
                </a>
                <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                US: +1-727-546-HOST(4678)
                </a>
                <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                UK: +44-20-3695-1294
                </a>
                <a href="/" className="my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700">
                AU: +61-2-8417-2372
                </a>
            </div>
        </div>

        
        <div className="pt-2">
            <div className="flex pb-5 px-3 m-auto pt-5 
                border-t border-white text-gray-400 text-sm 
                flex-col md:flex-row max-w-6xl">
                <div className="mt-2 text-white">
                    © Copyright 2003-2021 <a href="/">Sheephostingbay</a>. All Rights Reserved.
                </div>

                
                <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
                    <a href="/" className="mx-1">
                        <img src={Paypal} alt="Paypal" className="w-8"/>
                    </a>
                    <a href="/" className="mx-1">
                        <img src={Visa} alt="Visa" className="w-8"/>
                    </a>
                    <a href="/" className="mx-1">
                    <img src={Mastercard} alt="Mastercard" className="w-8"/>
                    </a>
                    <a href="/" className="mx-1">
                    <img src={Diners} alt="Diners Club" className="w-8"/>
                    </a>
                    <a href="/" className="mx-1">
                    <img src={JCB} alt="JCB" className="w-8"/>
                    </a>
                </div>
            </div>
        </div>
    </footer>
)


export default Footer
